import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Progress,
  Flex,
  Avatar,
  Text,
} from '@chakra-ui/react';
import api from '../../../utils/api.js';
import prices from '../../../utils/prices.js';
import ChangePassword from './change-password/ChangePassword.js';

import { useUserContext } from '../../../contexts/UserContext.js';
import { useAppContext } from '../../../contexts/AppContext.js';
import Header from '../../../layouts/Header/Header.js';
import PaymentPlansUpdated from '../../../components/payment-stripe/payment-plans-updated/PaymentPlansUpdated.js';
import ProfileForm from '../../../components/my-account/profile-form/ProfileForm.js';
import { formatDateNoTime } from '../../../utils/formatDate.js';

import DeleteConfirmationModal from '../../../components/modals/delete-confirmation/DeleteConfirmationModal.js';

function bytesToMB(bytes, planType) {
  const currentMB = (bytes / (1024 * 1024)).toFixed(2);
  if (planType === 'free') {
    return (100 - currentMB).toFixed(2);
  } else {
    return (1000 - currentMB).toFixed(2);
  }
}

function Profile() {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { userDataAndPlans } = useUserContext();
  const { mobileMenuOpen, openMobileMenu } = useAppContext();
  const planType = userDataAndPlans.planType;
  const planCycleType = userDataAndPlans.planCycleType;
  const progressValue = (userDataAndPlans.wordsRemaining / prices[planType]?.[planCycleType].credits) * 100;

  const color = progressValue <= 50 && progressValue >= 30 ? "#FF6712" : progressValue < 30 ? '#FF1284' : "#27EE07";
  useEffect(() => {
    if (mobileMenuOpen === true && window.innerWidth < 1200) {
      openMobileMenu(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelSubscription = async () => {
    await api.post('/admin/payment/cancel-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    // const status = response.data;
    window.location.reload();
  }

  return (
    <>
      <Header page="myAccount" title="My Account" />
      <Flex sx={myAccountPageStyle}>
        {userDataAndPlans && userDataAndPlans.id &&
          <>
            <Flex className="topStructure">
              <Box className="leftBox">
                <Box className="avatarContainerDetails">
                  <Avatar style={{ fontWeight: 'bold', fontSize: '30px' }} w="64px" h="64px" name={userDataAndPlans.lastname.charAt(0).toUpperCase()} bgColor="#1E5DFF" mb="10px" />
                  <Box className="userDetails">
                    <Box className="editContainer">
                      <Text sx={userNameStyle}>{userDataAndPlans.firstname} {userDataAndPlans.lastname}</Text>
                      <ProfileForm />
                      <Box className="passwordContainer">
                        <ChangePassword />
                      </Box>
                    </Box>
                    <Text sx={userEmailStyle}>{userDataAndPlans.email}</Text>

                    <Box className="planDetails">
                      <Text className={`planType ${userDataAndPlans.planType}`}>
                        {userDataAndPlans.planType}
                      </Text>
                      {userDataAndPlans.planType === 'free'
                        ? <Text className="newCredits creditsAndPaymentText">
                          Credits Renew {formatDateNoTime(userDataAndPlans.renewalDate)}
                        </Text>
                        : <Box className="newPayment">
                          <Text className="creditsAndPaymentText">Next Payment: {formatDateNoTime(userDataAndPlans.renewalDate)}</Text>
                          <Button className="cancelSubscription" onClick={() => setIsDeleteModalOpen(true)}>Cancel subscription</Button>
                        </Box>
                      }

                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="rightBox">
                <Box sx={remainingCreditsStyle}>
                  <Box className="creditsContainer">
                    <Text className="currentCredits">{userDataAndPlans.wordsRemaining}</Text>
                    <Text className="totalCredits">/ {prices[planType]?.[planCycleType].credits} credits</Text>
                  </Box>
                  <Progress
                    value={progressValue}
                    colorScheme={'#6D99BE'}
                    sx={{
                      "> div": {
                        bg: color,  // This sets the background color of the track
                      },
                      background: "#6D99BE",
                      borderRadius: "120px",
                      height: "5px"
                    }}
                    mb="10px"
                  />
                  <Box className="fileStorageContainer">
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
                      <path d="M1 8.00001C1 7.57044 1.34823 7.22223 1.77778 7.22223H14.2222C14.6518 7.22223 15 7.57044 15 8.00001V11.1111C15 11.9703 14.3036 12.6667 13.4444 12.6667H2.55556C1.69645 12.6667 1 11.9703 1 11.1111V8.00001Z" stroke="#8599CD" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M15 8L13.7151 2.21811C13.557 1.50638 12.9257 1 12.1967 1H3.80338C3.07429 1 2.44303 1.50638 2.28487 2.21811L1 8" stroke="#8599CD" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M3.33301 10.3333V10.3411" stroke="#8599CD" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.66699 10.3333V10.3411" stroke="#8599CD" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <Text>{bytesToMB(userDataAndPlans.storage, userDataAndPlans.planType)} MB free of {userDataAndPlans.planType === 'free' ? '100 MB' : '1 GB'}</Text>
                  </Box>
                </Box>
              </Box>
            </Flex>
            <PaymentPlansUpdated userDataAndPlans={userDataAndPlans} />
            <DeleteConfirmationModal
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              onDelete={() => cancelSubscription()}
              itemName="subscription"
            />
          </>
        }
      </Flex>
    </>

  );
}

const remainingCreditsStyle = {
  '.creditsContainer': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginBottom: '10px',
    '.currentCredits': {
      fontSize: '24px',
      lineHeight: '28px',
      color: '#19D02B'
    },
    '.totalCredits': {
      fontSize: '12px',
      lineHeight: '14px',
      color: '#8599CD',
      textTransform: 'uppercase'
    }
  }


}

const myAccountPageStyle = {
  flexDirection: 'column',
  paddingTop: '60px',
  maxWidth: '850px',
  marginLeft: 'auto',
  marginRight: 'auto',
  '.topStructure': {
    paddingBottom: '40px',
    '.leftBox': {
      flex: '0 0 50%',
      paddingRight: '15px',
    },
    '.rightBox': {
      flex: '0 0 50%',
      paddingLeft: '15px',
    },
    '.avatarContainerDetails': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'top',
      paddingTop: '5px',
      '.userDetails': {
        paddingLeft: '15px',
        '.editContainer': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          '.passwordContainer': {
            paddingLeft: '10px',
            display: 'none',
            'button': {
              minWidth: '25px',
              height: '25px',
              width: '25px',
              padding: 0,
              border: '0 none',
              'svg': {
                'path': {
                  stroke: '#1E5DFF'
                }
              }
            }
          }
        },

        '.planDetails': {
          display: 'flex',
          flexDirection: 'row',
          '.planType': {
            marginRight: '10px',
            height: '30px',
            lineHeight: '30px',
            padding: '0 10px',
            background: '#F2F9FF',
            color: '#8599CD',
            fontSize: '12px',
            textTransform: 'uppercase',
            borderRadius: '4px',
            '&.premium': {
              color: '#fff',
              background: '#1E5DFF'
            }
          },
          '.creditsAndPaymentText': {
            color: '#98B3F8',
            fontSize: '12px'
          },
          '.newPayment ': {
            '.creditsAndPaymentText': {
              color: '#1B518F',
            },
            '.cancelSubscription': {
              padding: 0,
              background: 'transparent',
              fontSize: '12px',
              color: '#98B3F8',
              height: 'auto'
            }
          }
        }
      }
    },
    '.fileStorageContainer': {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: '10px',
      'svg': {
        marginRight: '10px'
      },
      'p': {
        fontSize: '12px',
        lineHeight: '14px',
        color: '#98B3F8'
      }
    }
  },
  '@media all and (max-width: 1200px)': {
    paddingTop: '30px',
    '.topStructure': {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    '.userDetails': {
      '.passwordContainer': {
        display: 'block'
      }
    }
  },
  '@media all and (max-width: 680px)': {
    '.topStructure': {
      flexWrap: 'wrap',
      '.leftBox': {
        flex: '0 0 100%',
        paddingRight: 0,
        marginBottom: '20px'
      },
      '.rightBox': {
        flex: '0 0 100%',
        paddingLeft: 0
      }
    },
  }
}

const userNameStyle = {
  color: '#1B518F',
  fontSize: '18px',
  lineHeight: '22px',
  fontWeight: 600
}

const userEmailStyle = {
  fontSize: '14px',
  lineHeight: '16px',
  color: '#8599CD',
  marginBottom: '12px',
}


export default Profile;
